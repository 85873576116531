:local(.validation-label) {
    color: var(--pink);
}

:local(.spinner-container) {
    flex-grow: 0;
}

:local(.spinner-label) {
    align-items: center;
    align-self: center;
}
