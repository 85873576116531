:local(.CertificateSummaryContainer) {
    width: 100%;
}

:local(.CertificateSummaryComment) {
    padding: 2%;
    text-align: justify;
}

:local(.materialColQuantity) {
    align-self: center;
}