:local(.footer_four) {
  background-color: #080708;
  padding: 100px 0;
  width: 100%;
}

:local(.footer_four) :local(.footer_menu) {
  list-style: none;
  text-align: center;
}

:local(.footer_four) :local(.footer_menu) li {
  float: none;
  padding: 0;
  display: inline-block;
}

:local(.footer_four) :local(.footer_menu) li a {
  display: inline-block;
  color: #fafafa;
  font-size: 13px;
  padding: 4px 6px;
  text-transform: capitalize;
}

:local(.footer_four) :local(.footer_menu) li a:hover {
  color: var(--pink);
}

:local(.footer_four) :local(.footer_soc) {
  list-style: none;
  text-align: center;
  padding: 10px 0;
}

:local(.footer_four) :local(.footer_soc) li {
  padding: 0;
  float: none;
  display: inline-block;
}

:local(.footer_four) :local(.footer_soc) a {
  color: #bdbdbd;
  font-size: 28px;
  display: inline-block;
  padding: 12px;
  text-align: center;
  margin: 2px;
}

:local(.footer_four) :local(.copy_right) {
  font-size: 13px;
  color: #999999;
  text-align: center;
}

:local(.footer_four) :local(.footer_logo) {
  text-align: center;
  max-width: 150px;
  margin: 0 auto 20px;
}

:local(.footer_dark) {
  background-color: #080708;
  color: #FFFFFF;
}

:local(.footer_dark) h4 {
  color: #ffffff;
}

:local(.footer_dark) p {
  color: #c3c3c3;
}

:local(.footer_dark) a {
  color: #c3c3c3;
}

:local(.footer_dark) a:hover {
  color: #ffffff;
}

:local(.footer_dark) a:focus {
  color: #ffffff;
}

:local(.footer_light) {
  background-color: #ffffff;
  color: #333333;
}

:local(.footer_light) h4 {
  color: #080708;
}

:local(.footer_light) p {
  color: #555555;
}

:local(.footer_gray) {
  background-color: #fafafa;
  color: #333333;
}

:local(.footer_gray) h4 {
  color: #080708;
}

:local(.footer_gray) p {
  color: #555555;
}

:local(.phone:hover), :local(.phone-bg) {
  background: #4fd5f8 !important;
  color: #ffffff !important;
}

:local(.phone-color:hover) {
  color: #4fd5f8 !important;
}

:local(.whatsapp-color:hover) {
    color: #25d366 !important;
}

:local(.whatsapp:hover), :local(.whatsapp-bg) {
    background: #25d366 !important;
    color: #ffffff !important;
}

:local(.facebook-color:hover) {
  color: #4b6ea8 !important;
}

:local(.facebook:hover), :local(.facebook-bg) {
  background: #4b6ea8 !important;
  color: #ffffff !important;
}

:local(.instagram-color:hover) {
  color: #d82950 !important;
}

:local(.instagram:hover), :local(.instagram-bg) {
  background: #d82950 !important;
  color: #ffffff !important;
}

:local(.mail-color:hover) {
    color: #da2200 !important;
}

:local(.mail:hover), :local(.mail-bg) {
    background:#da2200 !important;
    color: #ffffff !important;
}