:local(.custom-form) {
    width: 100%;
}

:local(.privacy-check) {
    margin-left: 10%;
}

.modal-form .form-check-input.is-valid+.form-check-label,
.modal-form .was-validated .form-check-input:valid+.form-check-label {
    color: var(--grey);
}