:local(.order-col-subtitle) {
    margin: 5%;
    color: var(--pink);
}

:local(.distributor-list) {
    margin: 0.5%;
    display: inline-block;
}

:local(.distributor-list a) {
    color: var(--grey);
}

:local(.distributor-list a:hover) {
    color: var(--pink-150);
}

:local(.distributor-container) {
    text-align: justify;
    display: inline-block;
}