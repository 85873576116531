.CookiesModal__cookies-container_3YI5mkmiwg {
    text-align: justify;
}
.CookieAlert__cookie-button_2W8QcCFC6i {
    color: white !important;
    background-color: var(--pink-150) !important;
    border-radius: 5px !important;
}
.Header__header_c2eeQzi3t0 {
    min-height: 50px;
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.04);
    background-color: var(--grey-50);
    z-index: 9999;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
}

.Header__title_sEFPt5v0cF {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1.3em;
  font-family: "Roboto", sans-serif;
  color: var(--pink);
  display: inline-block;
}

.Header__subtitle_3_re0Bn0OX {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 0.5em;
  font-family: "Roboto", sans-serif;
  color: white;
  display: inline-block;
  margin-left: 2%;
}

.Header__subtitleContainer_3HuW7Nxgaw {
  -webkit-align-self: center;
          align-self: center;
  text-align: left;
}

.Header__header_c2eeQzi3t0 .navbar-brand {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1.3em;
    font-family: "Roboto", sans-serif;
    color: var(--pink);
}

.Header__header_c2eeQzi3t0 .navbar-brand span {
    margin-top: 3px;
    display: inline-block;
    margin-left: 5%;
    color: var(--pink) !important;
}

.Header__header_c2eeQzi3t0 .navbar-brand img {
    max-width: 125px;
    margin-right: 3px;
}

.Header__logo-container_cjnGXVA6md {
    display: inline-block;
}

.Header__logo_29XbX8Mz7S {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
            filter: FlipH;
    -ms-filter: "FlipH";
}

.Header__header_c2eeQzi3t0 .menu {
  position: relative;
}

.Header__header_c2eeQzi3t0 .menu li {
  padding: 0;
}

.Header__header_c2eeQzi3t0 .menu li a.nav-link {
  font-size: 13px;
  text-transform: capitalize;
  display: block;
  color: #ffffff;
  margin: 0;
  padding: 20px 12px !important;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.Header__header_c2eeQzi3t0 .menu li a.nav-link:hover {
  color: var(--pink);
}

.Header__header_c2eeQzi3t0 .menu li a.nav-link.active {
  color: var(--pink);
}

.Header__header_c2eeQzi3t0 a.has_sub_menu.nav-link {
  position: relative;
  padding-right: 15px;
}

.Header__header_c2eeQzi3t0 a.has_sub_menu.nav-link:after {
  font-family: FontAwesome;
  content: "\F107";
  position: absolute;
  right: 0;
  display: inline-block;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  top: 5px;
}

.Header__header_c2eeQzi3t0 li ul {
  display: none;
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.Header__header_c2eeQzi3t0 li ul.multi_col {
  position: absolute;
  list-style: none;
  margin: 0;
  white-space: nowrap;
  background: #1f1f1f;
  border-radius: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 20px;
  min-width: 250px;
  float: left;
  right: 0;
}

.Header__header_c2eeQzi3t0 li ul.multi_col li {
  min-width: 200px;
  display: inline-block;
}

.Header__header_c2eeQzi3t0 li ul.multi_col li ul {
  display: block;
  visibility: visible;
  list-style: none;
  padding: 0;
  margin: 0;
}

.Header__header_c2eeQzi3t0 li ul.multi_col li ul li {
  display: block;
}

.Header__header_c2eeQzi3t0 li ul.multi_col li ul li a {
  color: #999999 !important;
  padding: 7px 10px;
  display: block;
  margin-left: 0;
  line-height: 22px;
  word-break: break-all;
}

.Header__header_c2eeQzi3t0 li ul.multi_col li ul li a:hover {
  color: #ffffff !important;
  transform: translate3d(2px, 0, 0);
  -webkit-transform: translate3d(2px, 0, 0);
}

.Header__header_c2eeQzi3t0 li ul.sub_menu {
  background-color: #333333;
  border-radius: 3px;
  position: absolute;
  padding: 0;
  min-width: 220px;
  width: auto;
  height: auto;
  margin: 0;
  opacity: 1;
  visibility: hidden;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.04);
}

.Header__header_c2eeQzi3t0 li ul.sub_menu li {
  display: block;
  padding: 0;
}

.Header__header_c2eeQzi3t0 li ul.sub_menu li a.nav-link {
  padding: 8px 15px !important;
  display: block;
  margin-left: 0;
  word-break: break-word;
  border-bottom: solid 1px #f5f5f5;
}

.Header__header_c2eeQzi3t0 .admin-bar .Header__header_c2eeQzi3t0.Header__sticky_header_19lgYkrs8Q {
  margin-top: 30px;
}

.Header__header_c2eeQzi3t0 a.nav-link.active_menu.has_sub_menu:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.Header__header_c2eeQzi3t0 .Header__header_c2eeQzi3t0 a.has_sub_menuu .nav-link:after {
  font-family: FontAwesome;
  content: "\F105";
  position: absolute;
  right: 0;
  display: inline-block;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  top: 5px;
}

.sub_menu.depth-1 {
  right: 0;
  left: 100%;
  top: 0;
}

.Header__header_c2eeQzi3t0.Header__sticky_header_19lgYkrs8Q {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header_menu_btn {
  padding: 21px 25px;
  font-size: 22px;
  outline: 0 !important;
  box-shadow: 0 0 0 !important;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  background-color: transparent;
  border: 0;
  display: inline-block;
  text-align: center;
  margin: 0;
}

.header_menu_btn .navbar-toggler-icon {
  width: 20px;
  height: 3px;
  display: block;
  background-color: #080708;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #1f1f1f;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #1f1f1f;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

@media (min-width: 992px) {
  .Header__header_c2eeQzi3t0 .navbar-brand {
    font-size: 2em;
  }
  .Header__header_c2eeQzi3t0 li:hover > ul {
    display: block;
    visibility: visible;
  }
  .Header__header_c2eeQzi3t0 a.nav-link.has_sub_menu {
    position: relative;
  }
  .Header__header_c2eeQzi3t0 a.nav-link.has_sub_menu:after {
    content: "\F107";
    font-family: FontAwesome;
    top: 1px;
    right: 0;
    margin: 0 4px;
    font-size: 13px;
    position: relative;
  }
}

@media (max-width: 992px) {
  .Header__header_c2eeQzi3t0 {
    padding: 5px 15px;
  }
  .Header__header_c2eeQzi3t0.Header__sticky_header_19lgYkrs8Q {
    position: relative;
  }
  .Header__header_c2eeQzi3t0 li > ul {
    position: static;
    visibility: visible;
    width: 100%;
  }
  .Header__header_c2eeQzi3t0 .menu li a.nav-link {
    border-top: solid 1px #f7f7f7;
    border-bottom: solid 1px #f7f7f7;
    border-radius: 0;
    box-shadow: 0 0 0;
  }
  .Header__header_c2eeQzi3t0 .menu li a.nav-link:last-child {
    border-bottom: 0;
  }
  .Header__header_c2eeQzi3t0 li ul.multi_col {
    position: static;
    visibility: visible;
    width: 100%;
  }
  .Header__header_c2eeQzi3t0 li ul.multi_col li {
    display: block;
    width: 100%;
  }
  .Header__header_c2eeQzi3t0 li ul.sub_menu {
    position: static;
    visibility: visible;
    width: 100%;
    box-shadow: 0 0 0;
  }
  .Header__header_c2eeQzi3t0 ul.menu {
    padding: 15px 0 0;
  }
  .Header__header_c2eeQzi3t0 ul.menu li a.nav-link {
    padding: 10px 5px !important;
    border-bottom: solid 1px #f5f5f5;
  }
  .Header__header_c2eeQzi3t0 ul.menu li:last-child a {
    border-bottom: 0;
  }
  .Header__header_c2eeQzi3t0 a.has_sub_menu.nav-link:after {
    margin-right: 15px;
    top: 12px;
  }
  .header_btn {
    display: inline-block;
    width: 100%;
    margin: 15px auto;
  }
}

@media (max-width: 350px) {
  .Header__header_c2eeQzi3t0 .navbar-brand img {
    max-width: 100px;
  }
}

@media (max-width: 378px) {
  .Header__header_c2eeQzi3t0 .navbar-brand {
    font-size: 1.1em !important;
  }
}

@media (max-width: 576px) {
  .Header__subtitleContainer_3HuW7Nxgaw {
    text-align: center !important;
  }
}

@media (max-width: 576px) {
  .Header__subtitle_3_re0Bn0OX {
    font-size: 0.7em !important;
  }
}

.News__news-toast_xLZMAtnmOs {
    width: 30%;
}

@media only screen and (max-width: 768px) {
    .Toastify__toast-container {
      display: none;
    }
}

.Advices__news-toast_7S1nu9oLGv {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .Toastify__toast-container {
      display: none;
    }
}

.GRPDModal__grpd-container_1oZ4fKTmP- {
    text-align: justify;
}
.TermsModal__terms-container_2Kcg1AtXzi {
    text-align: justify;
}
.Spinner__loader_1x8f2s2oAr {
    display: block;
    width: 60px;
    height: 60px;
}

.Spinner__loader_1x8f2s2oAr .Spinner__loader-inner_2MWvjf8pzL {
    position: relative;
    border: 2px solid #ff0090;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    border-right: 0;
    border-top-color: transparent;
    -webkit-animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.Spinner__loader_1x8f2s2oAr .Spinner__loader-outter_6z-hWmX9gw {
    float: left;
    position: relative;
    border: 2px solid #333333;
    border-left-color: transparent;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    -webkit-animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes Spinner__loader-outter_6z-hWmX9gw {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

@keyframes Spinner__loader-outter_6z-hWmX9gw {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}

@-webkit-keyframes Spinner__loader-inner_2MWvjf8pzL {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
}

@keyframes Spinner__loader-inner_2MWvjf8pzL {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
}
.WorkWithUsModal__validation-label_1bQPySXjub {
    color: var(--pink);
}

.WorkWithUsModal__spinner-container_cbr7JJcAjI {
    -webkit-flex-grow: 0;
            flex-grow: 0;
}

.WorkWithUsModal__spinner-label_2_FYc9EAoz {
    -webkit-align-items: center;
            align-items: center;
    -webkit-align-self: center;
            align-self: center;
}

.Footer__footer_four_2HB01snpw1 {
  background-color: #080708;
  padding: 100px 0;
  width: 100%;
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_menu_3rAM-T2RmI {
  list-style: none;
  text-align: center;
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_menu_3rAM-T2RmI li {
  float: none;
  padding: 0;
  display: inline-block;
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_menu_3rAM-T2RmI li a {
  display: inline-block;
  color: #fafafa;
  font-size: 13px;
  padding: 4px 6px;
  text-transform: capitalize;
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_menu_3rAM-T2RmI li a:hover {
  color: var(--pink);
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_soc_1d658S2VDA {
  list-style: none;
  text-align: center;
  padding: 10px 0;
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_soc_1d658S2VDA li {
  padding: 0;
  float: none;
  display: inline-block;
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_soc_1d658S2VDA a {
  color: #bdbdbd;
  font-size: 28px;
  display: inline-block;
  padding: 12px;
  text-align: center;
  margin: 2px;
}

.Footer__footer_four_2HB01snpw1 .Footer__copy_right_1bcIaVp6mV {
  font-size: 13px;
  color: #999999;
  text-align: center;
}

.Footer__footer_four_2HB01snpw1 .Footer__footer_logo_2lOIA-6IdR {
  text-align: center;
  max-width: 150px;
  margin: 0 auto 20px;
}

.Footer__footer_dark_1wwmJrJD7i {
  background-color: #080708;
  color: #FFFFFF;
}

.Footer__footer_dark_1wwmJrJD7i h4 {
  color: #ffffff;
}

.Footer__footer_dark_1wwmJrJD7i p {
  color: #c3c3c3;
}

.Footer__footer_dark_1wwmJrJD7i a {
  color: #c3c3c3;
}

.Footer__footer_dark_1wwmJrJD7i a:hover {
  color: #ffffff;
}

.Footer__footer_dark_1wwmJrJD7i a:focus {
  color: #ffffff;
}

.Footer__footer_light_oPT5yxV236 {
  background-color: #ffffff;
  color: #333333;
}

.Footer__footer_light_oPT5yxV236 h4 {
  color: #080708;
}

.Footer__footer_light_oPT5yxV236 p {
  color: #555555;
}

.Footer__footer_gray_1ikx3xZ5c6 {
  background-color: #fafafa;
  color: #333333;
}

.Footer__footer_gray_1ikx3xZ5c6 h4 {
  color: #080708;
}

.Footer__footer_gray_1ikx3xZ5c6 p {
  color: #555555;
}

.Footer__phone_39WBdkFmEe:hover, .Footer__phone-bg_74A6a9OrZv {
  background: #4fd5f8 !important;
  color: #ffffff !important;
}

.Footer__phone-color_16ifIU4mD8:hover {
  color: #4fd5f8 !important;
}

.Footer__whatsapp-color_1rn6P5qHDG:hover {
    color: #25d366 !important;
}

.Footer__whatsapp_3j2n6RQ61G:hover, .Footer__whatsapp-bg_2JBdsTrI9I {
    background: #25d366 !important;
    color: #ffffff !important;
}

.Footer__facebook-color_3-DeRHzTQ3:hover {
  color: #4b6ea8 !important;
}

.Footer__facebook_1eZIc0mGUx:hover, .Footer__facebook-bg_1DPRlYeJTm {
  background: #4b6ea8 !important;
  color: #ffffff !important;
}

.Footer__instagram-color_2i1Pz2x-Rd:hover {
  color: #d82950 !important;
}

.Footer__instagram_3QlGcoq9aG:hover, .Footer__instagram-bg_iXiWtGtMgr {
  background: #d82950 !important;
  color: #ffffff !important;
}

.Footer__mail-color_1c_sFiM94h:hover {
    color: #da2200 !important;
}

.Footer__mail_3C37Evtd_E:hover, .Footer__mail-bg_15_5vEUseu {
    background:#da2200 !important;
    color: #ffffff !important;
}
.CustomForm__custom-form_300_IDQ9d- {
    width: 100%;
}

.CustomForm__privacy-check_cTvdOldZaq {
    margin-left: 10%;
}
.FormErrorMsg__formError_2PJfsm7tZN {
    color: black !important;
    background-color: var(--error-yellow) !important;
    border-color: var( --pink-150) !important;
}

.FormErrorMsg__FormErrorIconBox_2rj4HUrLoV {
    visibility: visible !important;
}

.FormErrorMsg__formErrorTitle_1H6vatBJ83 {
    color: var(--pink-50) !important;
}

.FormErrorMsg__FormErrorText_3tAzZNkP6k {
    color: black !important;
}

.FormErrorMsg__FormErrorResaltedText_2yTNcXwFZh {
    color: var(--pink);
}

.ModalForm__custom-form_2Sb_8ECKuQ {
    width: 100%;
}

.ModalForm__privacy-check_k8VXJEGd0E {
    margin-left: 10%;
}

.modal-form .form-check-input.is-valid+.form-check-label,
.modal-form .was-validated .form-check-input:valid+.form-check-label {
    color: var(--grey);
}
.PickupSummary__pickup-summary-container_17zWZwtRhf {
    width: 100%;
}

.PickupSummary__PickupSummaryComment_3iUJpAraS6 {
    padding: 2%;
    text-align: justify;
}

.CertificateSummary__CertificateSummaryContainer_1S8CkfRgLK {
    width: 100%;
}

.CertificateSummary__CertificateSummaryComment_3ZCylB7ayt {
    padding: 2%;
    text-align: justify;
}

.CertificateSummary__materialColQuantity_2SxbSjcPI9 {
    -webkit-align-self: center;
            align-self: center;
}
.MaterialsSelector__materialsSelect_226Rf28g6j {
    height: auto !important;
}

.MaterialsSelector__quantityInput_1X0N7IAcSM {
    margin-bottom: 0px;
}

.MaterialsSelector__alertMessage_3fxsFyT0Nk {
    color: var(--pink);
    margin-left: 1em;
}

.MaterialsSelector__ButtonContainer_3iFur2ZcFG {
    -webkit-align-self: center;
            align-self: center;
}

.MaterialsSelector__addButton_1JeDKZzOwN {
    width: 2em !important;
    padding: .5em !important;
    line-height: NORMAL !important;
    height: 2em !important;
}

.MaterialsSelector__invalidMaterialList_3y2BD9ktvX {
    background-color: var(--error-red);
    width: 100%;
    margin: auto;
    border-radius: 5px;
}

.PickupModal__pickup-select_CGnD1yeuQT {
    height: auto !important;
}

.PickupModal__tags-input-container_1CSTl5mR1O {
    padding-top: 0.7em;
    height: auto;
    border-color: #f5f5f5
}

.PickupModal__tags-input-container-invalid_2ypKvtVFWn {
    padding-top: 0.7em;
    height: auto;
    border-color: var(--pink);
}

.PickupModal__tags-input-container-focused_cOUHeH3vXH {
    padding-top: 0.7em;
    height: auto;
    border-color: var(--pink-150);
}

.PickupModal__tags-input-tags_YMRCG9S9Br {
    color: var(--pink);
    background-color: var(--pink-200);
    border-color: var(--pink);
}

.PickupModal__tags-input-input_J_mB0sRHu2 {
    width: 13em;
}

.PickupModal__tagsInputIncorrectInput_3fkZcY6YV4 {
    width: 16.5em;
}

.PickupModal__tagsInputIncorrectInput_3fkZcY6YV4::-webkit-input-placeholder {
    color: var(--pink);
}

.PickupModal__tagsInputIncorrectInput_3fkZcY6YV4::-ms-input-placeholder {
    color: var(--pink);
}

.PickupModal__tagsInputIncorrectInput_3fkZcY6YV4::placeholder {
    color: var(--pink);
}

.PickupModal__tagsInputError_1gvl3yaoIG {
    color: var(--pink);
}

.PickupModal__switch_1N74Dlo7YD {
    margin-right: 1em;
    margin-bottom: 1em;
}

.PickupModal__switch-content_SoA2wJNtY7 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    font-size: 15;
    padding-right: 2;
}

.PickupModal__switch-checked_hTqspC-YvK {
    color: white;
}

.PickupModal__switch-unchecked_14fGR6QxYD {
    color: var(--grey-50);
}

.PickupModal__certificateTitle_1DlToHh2Dj {
    font-size: 1.5em;
    color: var(--grey-200);
}

.PickupModal__certificateContainer_Decghf9bwr {
    -webkit-transition: height 500ms;
    transition: height 500ms;
    background-color: #EEEEEE;
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    margin-bottom: 1em;
}

.PickupModal__certificateContent_fx8rDpFcoD {
    padding: 0.5em;
}

.PickupModal__certificateSection_1Sz-DRzYuI {
    margin-top: 1em;
    background-color: white;
    padding: .5em;
    border-radius: 4px;
}

.PickupModal__certificateSectionTitle_1C8hG3Ztmg {
    color: var(--pink-150);
    font-weight: bold;
}

.PickupModal__certificateCalendarInputsContainer_3QXJphbxbs {
    margin: auto;
}

.PickupModal__certificateFileValidationLabel_12nR-cCynu {
    color: var(--pink);
}

.PickupModal__ProducerBuilderCheck_x1b65raKp3 {
    margin: 0.5em;
}

.PickupModal__BuilderSectionCollapseContainer_3PtsUZC5e_ {
    -webkit-transition: height 500ms;
    transition: height 500ms;
    background-color: #EEEEEE;
    margin-bottom: 1em;
}

.PickupModal__MaterialsBreakDownRow_rAntt8Y5vx {
    padding-left: 1.5em;
}

.PickupModal__MaterialsBreakDownRow_rAntt8Y5vx div {
    -webkit-align-self: center;
            align-self: center;
}

@media (max-width: 992px) {
    .PickupModal__MaterialsBreakDownHeader_1jSv43Tyyu {
      display: -webkit-flex;
      display: flex;
    }
}

@media (min-width: 992px) {
    .PickupModal__MaterialsBreakDownHeader_1jSv43Tyyu {
      display: none;
    }
}

.PickupModal__removeMaterialButton_ZNOPA6_O8P {
    width: 2em !important;
    padding: .5em !important;
    line-height: NORMAL !important;
    height: 2em !important;
}

.DateRangePicker__CalendarHighlight--single {
    border: 1px solid var(--pink) !important;
}

.DateRangePicker__CalendarSelection--is-pending {
    background-color: var(--pink-200) !important;
    border: 1px solid var(--pink-200) !important;
}

.DateRangePicker__CalendarSelection {
    background-color: var(--pink-150);
    border: 1px solid var(--pink);
}
.leaflet-container {
    margin: 0 auto;
    width: 100%;
    height: 400px;
}

.Pickups__ButtonContainer_3Q1WQjGfcj {
    margin: 0 auto;
}

.OrderForm__OrderSummaryContainer_3Z6-Ypv-oQ {
    width: 100%;
}

.OrderForm__OrderSummaryComment_xXnCzTS-LQ {
    padding: 2%;
    text-align: justify;
}

.OrderForm__form-button_nDQrKJnwe- {
    width: 100%;
}

#orders_section .form-check-input.is-valid+.form-check-label,
#orders_section .was-validated .form-check-input:valid+.form-check-label {
    color: var(--grey);
}
.Orders__order-col-subtitle_Sgigewp0Ax {
    margin: 5%;
    color: var(--pink);
}

.Orders__distributor-list_5a9IdtPpEk {
    margin: 0.5%;
    display: inline-block;
}

.Orders__distributor-list_5a9IdtPpEk a {
    color: var(--grey);
}

.Orders__distributor-list_5a9IdtPpEk a:hover {
    color: var(--pink-150);
}

.Orders__distributor-container_2sKoKpUzrG {
    text-align: justify;
    display: inline-block;
}
.Contact__form-button_yzuRmqr4ls {
    width: 100%;
}

@media (max-width: 575px) {
    .Contact__contact-form_2gNlpr-xFT {
        padding: 3%;
    }
}
.Catalog__catalog-container_1eMM4nUmiL {
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 390px) {
    .project_box_one .product_info {
      padding: 15px 20px;
    }
}

@media (max-width: 340px) {
    .project_box_one .product_info {
      line-height: 1.5em;
    }
}

.Maintenance__maintenanceContainer_1ZKWOE3Q51 {
    text-align: center;
}

.Maintenance__maintenanceTitle_3FgbJWkmhH {
    color: var(--pink)
}

