
:local(.OrderSummaryContainer) {
    width: 100%;
}

:local(.OrderSummaryComment) {
    padding: 2%;
    text-align: justify;
}

:local(.form-button) {
    width: 100%;
}

#orders_section .form-check-input.is-valid+.form-check-label,
#orders_section .was-validated .form-check-input:valid+.form-check-label {
    color: var(--grey);
}