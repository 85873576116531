:local(.formError) {
    color: black !important;
    background-color: var(--error-yellow) !important;
    border-color: var( --pink-150) !important;
}

:local(.FormErrorIconBox) {
    visibility: visible !important;
}

:local(.formErrorTitle) {
    color: var(--pink-50) !important;
}

:local(.FormErrorText) {
    color: black !important;
}

:local(.FormErrorResaltedText) {
    color: var(--pink);
}
