:local(.materialsSelect) {
    height: auto !important;
}

:local(.quantityInput) {
    margin-bottom: 0px;
}

:local(.alertMessage) {
    color: var(--pink);
    margin-left: 1em;
}

:local(.ButtonContainer) {
    align-self: center;
}

:local(.addButton) {
    width: 2em !important;
    padding: .5em !important;
    line-height: NORMAL !important;
    height: 2em !important;
}

:local(.invalidMaterialList) {
    background-color: var(--error-red);
    width: 100%;
    margin: auto;
    border-radius: 5px;
}
