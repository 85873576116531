:local(.catalog-container) {
    width: 70%;
    margin: 0 auto;
}

@media (max-width: 390px) {
    .project_box_one .product_info {
      padding: 15px 20px;
    }
}

@media (max-width: 340px) {
    .project_box_one .product_info {
      line-height: 1.5em;
    }
}
