:local(.pickup-select) {
    height: auto !important;
}

:local(.tags-input-container) {
    padding-top: 0.7em;
    height: auto;
    border-color: #f5f5f5
}

:local(.tags-input-container-invalid) {
    padding-top: 0.7em;
    height: auto;
    border-color: var(--pink);
}

:local(.tags-input-container-focused) {
    padding-top: 0.7em;
    height: auto;
    border-color: var(--pink-150);
}

:local(.tags-input-tags) {
    color: var(--pink);
    background-color: var(--pink-200);
    border-color: var(--pink);
}

:local(.tags-input-input) {
    width: 13em;
}

:local(.tagsInputIncorrectInput) {
    width: 16.5em;
}

:local(.tagsInputIncorrectInput::placeholder) {
    color: var(--pink);
}

:local(.tagsInputError) {
    color: var(--pink);
}

:local(.switch) {
    margin-right: 1em;
    margin-bottom: 1em;
}

:local(.switch-content) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 15;
    padding-right: 2;
}

:local(.switch-checked) {
    color: white;
}

:local(.switch-unchecked) {
    color: var(--grey-50);
}

:local(.certificateTitle) {
    font-size: 1.5em;
    color: var(--grey-200);
}

:local(.certificateContainer) {
    transition: height 500ms;
    background-color: #EEEEEE;
    border: 1px solid var(--grey-200);
    border-radius: 4px;
    margin-bottom: 1em;
}

:local(.certificateContent) {
    padding: 0.5em;
}

:local(.certificateSection) {
    margin-top: 1em;
    background-color: white;
    padding: .5em;
    border-radius: 4px;
}

:local(.certificateSectionTitle) {
    color: var(--pink-150);
    font-weight: bold;
}

:local(.certificateCalendarInputsContainer) {
    margin: auto;
}

:local(.certificateFileValidationLabel) {
    color: var(--pink);
}

:local(.ProducerBuilderCheck) {
    margin: 0.5em;
}

:local(.BuilderSectionCollapseContainer) {
    transition: height 500ms;
    background-color: #EEEEEE;
    margin-bottom: 1em;
}

:local(.MaterialsBreakDownRow) {
    padding-left: 1.5em;
}

:local(.MaterialsBreakDownRow) div {
    align-self: center;
}

@media (max-width: 992px) {
    :local(.MaterialsBreakDownHeader) {
      display: flex;
    }
}

@media (min-width: 992px) {
    :local(.MaterialsBreakDownHeader) {
      display: none;
    }
}

:local(.removeMaterialButton) {
    width: 2em !important;
    padding: .5em !important;
    line-height: NORMAL !important;
    height: 2em !important;
}

.DateRangePicker__CalendarHighlight--single {
    border: 1px solid var(--pink) !important;
}

.DateRangePicker__CalendarSelection--is-pending {
    background-color: var(--pink-200) !important;
    border: 1px solid var(--pink-200) !important;
}

.DateRangePicker__CalendarSelection {
    background-color: var(--pink-150);
    border: 1px solid var(--pink);
}