:local(.header) {
    min-height: 50px;
    padding: 0;
    margin: 0;
    -webkit-box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.04);
            box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.04);
    background-color: var(--grey-50);
    z-index: 9999;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
}

:local(.title) {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1.3em;
  font-family: "Roboto", sans-serif;
  color: var(--pink);
  display: inline-block;
}

:local(.subtitle) {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 0.5em;
  font-family: "Roboto", sans-serif;
  color: white;
  display: inline-block;
  margin-left: 2%;
}

:local(.subtitleContainer) {
  align-self: center;
  text-align: left;
}

:local(.header) .navbar-brand {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 1.3em;
    font-family: "Roboto", sans-serif;
    color: var(--pink);
}

:local(.header) .navbar-brand span {
    margin-top: 3px;
    display: inline-block;
    margin-left: 5%;
    color: var(--pink) !important;
}

:local(.header) .navbar-brand img {
    max-width: 125px;
    margin-right: 3px;
}

:local(.logo-container) {
    display: inline-block;
}

:local(.logo) {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

:local(.header) .menu {
  position: relative;
}

:local(.header) .menu li {
  padding: 0;
}

:local(.header) .menu li a.nav-link {
  font-size: 13px;
  text-transform: capitalize;
  display: block;
  color: #ffffff;
  margin: 0;
  padding: 20px 12px !important;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

:local(.header) .menu li a.nav-link:hover {
  color: var(--pink);
}

:local(.header) .menu li a.nav-link.active {
  color: var(--pink);
}

:local(.header) a.has_sub_menu.nav-link {
  position: relative;
  padding-right: 15px;
}

:local(.header) a.has_sub_menu.nav-link:after {
  font-family: FontAwesome;
  content: "\f107";
  position: absolute;
  right: 0;
  display: inline-block;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  top: 5px;
}

:local(.header) li ul {
  display: none;
  visibility: hidden;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

:local(.header) li ul.multi_col {
  position: absolute;
  list-style: none;
  margin: 0;
  white-space: nowrap;
  background: #1f1f1f;
  border-radius: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 20px;
  min-width: 250px;
  float: left;
  right: 0;
}

:local(.header) li ul.multi_col li {
  min-width: 200px;
  display: inline-block;
}

:local(.header) li ul.multi_col li ul {
  display: block;
  visibility: visible;
  list-style: none;
  padding: 0;
  margin: 0;
}

:local(.header) li ul.multi_col li ul li {
  display: block;
}

:local(.header) li ul.multi_col li ul li a {
  color: #999999 !important;
  padding: 7px 10px;
  display: block;
  margin-left: 0;
  line-height: 22px;
  word-break: break-all;
}

:local(.header) li ul.multi_col li ul li a:hover {
  color: #ffffff !important;
  transform: translate3d(2px, 0, 0);
  -webkit-transform: translate3d(2px, 0, 0);
}

:local(.header) li ul.sub_menu {
  background-color: #333333;
  border-radius: 3px;
  position: absolute;
  padding: 0;
  min-width: 220px;
  width: auto;
  height: auto;
  margin: 0;
  opacity: 1;
  visibility: hidden;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.04);
          box-shadow: 0 3px 9px rgba(0, 0, 0, 0.04);
}

:local(.header) li ul.sub_menu li {
  display: block;
  padding: 0;
}

:local(.header) li ul.sub_menu li a.nav-link {
  padding: 8px 15px !important;
  display: block;
  margin-left: 0;
  word-break: break-word;
  border-bottom: solid 1px #f5f5f5;
}

:local(.header) .admin-bar :local(.header.sticky_header) {
  margin-top: 30px;
}

:local(.header) a.nav-link.active_menu.has_sub_menu:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

:local(.header) :local(.header) a.has_sub_menuu .nav-link:after {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  right: 0;
  display: inline-block;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  top: 5px;
}

.sub_menu.depth-1 {
  right: 0;
  left: 100%;
  top: 0;
}

:local(.header.sticky_header) {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.header_menu_btn {
  padding: 21px 25px;
  font-size: 22px;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 !important;
  box-shadow: 0 0 0 !important;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  background-color: transparent;
  border: 0;
  display: inline-block;
  text-align: center;
  margin: 0;
}

.header_menu_btn .navbar-toggler-icon {
  width: 20px;
  height: 3px;
  display: block;
  background-color: #080708;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}

.header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #1f1f1f;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #1f1f1f;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

@media (min-width: 992px) {
  :local(.header) .navbar-brand {
    font-size: 2em;
  }
  :local(.header) li:hover > ul {
    display: block;
    visibility: visible;
  }
  :local(.header) a.nav-link.has_sub_menu {
    position: relative;
  }
  :local(.header) a.nav-link.has_sub_menu:after {
    content: "\f107";
    font-family: FontAwesome;
    top: 1px;
    right: 0;
    margin: 0 4px;
    font-size: 13px;
    position: relative;
  }
}

@media (max-width: 992px) {
  :local(.header) {
    padding: 5px 15px;
  }
  :local(.header.sticky_header) {
    position: relative;
  }
  :local(.header) li > ul {
    position: static;
    visibility: visible;
    width: 100%;
  }
  :local(.header) .menu li a.nav-link {
    border-top: solid 1px #f7f7f7;
    border-bottom: solid 1px #f7f7f7;
    border-radius: 0;
    -webkit-box-shadow: 0 0 0;
            box-shadow: 0 0 0;
  }
  :local(.header) .menu li a.nav-link:last-child {
    border-bottom: 0;
  }
  :local(.header) li ul.multi_col {
    position: static;
    visibility: visible;
    width: 100%;
  }
  :local(.header) li ul.multi_col li {
    display: block;
    width: 100%;
  }
  :local(.header) li ul.sub_menu {
    position: static;
    visibility: visible;
    width: 100%;
    -webkit-box-shadow: 0 0 0;
            box-shadow: 0 0 0;
  }
  :local(.header) ul.menu {
    padding: 15px 0 0;
  }
  :local(.header) ul.menu li a.nav-link {
    padding: 10px 5px !important;
    border-bottom: solid 1px #f5f5f5;
  }
  :local(.header) ul.menu li:last-child a {
    border-bottom: 0;
  }
  :local(.header) a.has_sub_menu.nav-link:after {
    margin-right: 15px;
    top: 12px;
  }
  .header_btn {
    display: inline-block;
    width: 100%;
    margin: 15px auto;
  }
}

@media (max-width: 350px) {
  :local(.header) .navbar-brand img {
    max-width: 100px;
  }
}

@media (max-width: 378px) {
  :local(.header) .navbar-brand {
    font-size: 1.1em !important;
  }
}

@media (max-width: 576px) {
  :local(.subtitleContainer) {
    text-align: center !important;
  }
}

@media (max-width: 576px) {
  :local(.subtitle) {
    font-size: 0.7em !important;
  }
}
